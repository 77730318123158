import { ReactNode } from "react";
import { AnalyticsContext } from "./analytics.context";
import { EventParams, getAnalytics, logEvent } from "firebase/analytics";
import { firebaseApp } from "./analytics.service";

type Props = {
    children: ReactNode;
};

export const AnalyticsProvider = ({ children }: Props) => {
    const analytics = getAnalytics(firebaseApp);

    const event = (event: string, params?: EventParams) => {
        if (analytics) {
            logEvent(analytics, event, params);
        }
    };

    return (
        <>
            <AnalyticsContext.Provider value={{ analytics, event }}>
                {children}
            </AnalyticsContext.Provider>
        </>
    );
};
