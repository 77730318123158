import React, { ComponentType, Ref, useImperativeHandle, useState } from "react";
import classes from "./ProductVariantSelector.module.scss";
import { ProductOption, ProductVariant } from "../../gql/graphql";

export type ProductVariantProps = {
    id: string;
    name: string;
    active: boolean;
    stockLevel: string;
    options: ProductOption[];

    onSelect?: (id: string) => void
};

type Params = {
    variants: Array<ProductVariant> | undefined;
    onSelected: (variant: ProductVariant | undefined) => void;
    PVComponent: ComponentType<ProductVariantProps>;
}

export interface ProductVariantSelectorRef {
    reset: () => void;
}

function ProductVariantSelector(
    { variants, onSelected, PVComponent }: Params,
    ref: Ref<ProductVariantSelectorRef>
) {
    const [variant, setVariant] = useState<ProductVariant | undefined>(undefined);
    
    const handleSelect = (id: string) => {
        const selected = variants?.find((variant: ProductVariant) => (variant.id === id));
        setVariant(selected);

        if (selected) {
            onSelected(selected);
        }
    }

    const reset = () => {
        setVariant(undefined);
        onSelected(undefined);
    };
    
    useImperativeHandle(ref, () => ({ reset }));
    
    return (
        <>
            <div className={classes.container}>
                {variants?.sort((a, b) => (
                    (+a.id) - (+b.id)
                )).map(({ id, name, stockLevel, options }: ProductVariant) => (
                    <PVComponent 
                        key={`variant-${id}`}
                        id={id}
                        name={name}
                        options={options}
                        stockLevel={stockLevel}
                        active={(variant?.id === id)}
                        onSelect={handleSelect} />
                ))}
            </div>
        </>
    )
}

export default React.forwardRef(ProductVariantSelector);
