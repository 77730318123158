import { createContext } from 'react';
import { Customer } from '../gql/graphql';

export interface ActiveCustomerContextInterface {
    customer?: Customer;
    isLoading: boolean;
    hasLoaded: boolean;
    error: string | undefined;
    clearActiveCustomer: () => void;
    fetch: () => void;
}

const defaultState = {
    customer: undefined,
    isLoading: false,
    hasLoaded: false,
    error: undefined,
    clearActiveCustomer: undefined,
    fetch: undefined,
};

export const ActiveCustomerContext = createContext<Partial<ActiveCustomerContextInterface>>(defaultState);
