import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import productEN from "./locales/en/product.json";
import productSV from "./locales/sv/product.json";

import homeEN from "./locales/en/home.json";
import homeSV from "./locales/sv/home.json";

import addToOrderFormEN from "./locales/en/addToOrderForm.json";
import addToOrderFormSV from "./locales/sv/addToOrderForm.json";

import navigationEN from "./locales/en/navigation.json";
import navigationSV from "./locales/sv/navigation.json";

import readyMadeEN from "./locales/en/readyMade.json";
import readyMadeSV from "./locales/sv/readyMade.json";

import madeToOrderEN from "./locales/en/madeToOrder.json";
import madeToOrderSV from "./locales/sv/madeToOrder.json";

import priceTagEN from "./locales/en/priceTag.json";
import priceTagSV from "./locales/sv/priceTag.json";

import cartEN from "./locales/en/cart.json";
import cartSV from "./locales/sv/cart.json";

import footerEN from "./locales/en/footer.json";
import footerSV from "./locales/sv/footer.json";

import registerEN from "./locales/en/register.json";
import registerSV from "./locales/sv/register.json";

import loginEN from "./locales/en/login.json";
import loginSV from "./locales/sv/login.json";

import addressEN from "./locales/en/address.json";
import addressSV from "./locales/sv/address.json";

import ordersEN from "./locales/en/orders.json";
import ordersSV from "./locales/sv/orders.json";

import userEN from "./locales/en/user.json";
import userSV from "./locales/sv/user.json";

import password_resetEN from "./locales/en/password_reset.json";
import password_resetSV from "./locales/sv/password_reset.json";

import customerEN from "./locales/en/customer.json";
import customerSV from "./locales/sv/customer.json";

import galleryEN from "./locales/en/gallery.json";
import gallerySV from "./locales/sv/gallery.json";

import servicesEN from "./locales/en/services.json";
import servicesSV from "./locales/sv/services.json";

import checkoutEN from "./locales/en/checkout.json";
import checkoutSV from "./locales/sv/checkout.json";

import receiptEN from "./locales/en/receipt.json";
import receiptSV from "./locales/sv/receipt.json";

import headerEN from "./locales/en/header.json";
import headerSV from "./locales/sv/header.json";

import findEN from "./locales/en/find.json";
import findSV from "./locales/sv/find.json";

import aboutEN from "./locales/en/about.json";
import aboutSV from "./locales/sv/about.json";

const resources = {
    en: {
        product: productEN,
        home: homeEN,
        addToOrderForm: addToOrderFormEN,
        navigation: navigationEN,
        readyMade: readyMadeEN,
        madeToOrder: madeToOrderEN,
        priceTag: priceTagEN,
        cart: cartEN,
        footer: footerEN,
        register: registerEN,
        login: loginEN,
        address: addressEN,
        orders: ordersEN,
        user: userEN,
        password_reset: password_resetEN,
        customer: customerEN,
        gallery: galleryEN,
        services: servicesEN,
        checkout: checkoutEN,
        receipt: receiptEN,
        header: headerEN,
        find: findEN,
        about: aboutEN,
    },
    sv: {
        product: productSV,
        home: homeSV,
        addToOrderForm: addToOrderFormSV,
        navigation: navigationSV,
        readyMade: readyMadeSV,
        madeToOrder: madeToOrderSV,
        priceTag: priceTagSV,
        cart: cartSV,
        footer: footerSV,
        register: registerSV,
        login: loginSV,
        address: addressSV,
        orders: ordersSV,
        user: userSV,
        password_reset: password_resetSV,
        customer: customerSV,
        gallery: gallerySV,
        services: servicesSV,
        checkout: checkoutSV,
        receipt: receiptSV,
        header: headerSV,
        find: findSV,
        about: aboutSV,
    }
};

i18n.use(initReactI18next).init({
    resources,
    lng: "sv",
    fallbackLng: ['sv'],
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;
