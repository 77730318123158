import React from "react";
import { Outlet } from "react-router-dom";
import { Header } from "../Header";
import { Footer } from "../Footer";
import { ConsentComponent } from "../../services/cookie-consent/components/ConsentComponent";

type Props = {
    children?: JSX.Element;
};

export default function Layout({ children }: Props) {

    return (
        <>
            <Header />
            {/* <Banner /> */}
            <main>
                <Outlet />
                
                {/* <Script
                    strategy="afterInteractive"
                    src="https://x.klarnacdn.net/kp/lib/v1/api.js" /> */}
            </main>
            <ConsentComponent />
            <Footer />
        </>
    )
}
