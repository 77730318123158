import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { FormEvent, useRef } from "react";
import { StripePaymentAction, StripePaymentRef } from "../../actions/StripeAction";
import { Button } from "../Button";
import classes from "./StripeForm.module.scss";
import { OrderAddress } from "../../gql/graphql";
import { useTranslation } from "react-i18next";
import { useToast } from "../../services/toasts";
import { ToastType } from "../../services/toasts";

type Props = {
    shippingAddress?: OrderAddress;
    orderCode?: string;
    show: boolean;
}

export default function StripeForm({ shippingAddress, orderCode, show }: Props) {
    const { t } = useTranslation("checkout");

    const stripePaymentAction = useRef<StripePaymentRef>(null);
    
    const stripe = useStripe();
    const elements = useElements();

    const toast = useToast();

    const handleSubmit = async (event: FormEvent) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();
    
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }
    
        const result = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: window.location.origin + `/receipt/${orderCode}`,
            },
        });
    
        if (result.error) {
            if (result.error.message) {
                toast.open(result.error.message, ToastType.Warning);
            } else {
                toast.open("Unknown payment error, try again.");
            }
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
            console.log(result);
        }
    };

    let classnames = "";

    if (show) {
        classnames = [].join(" ");
    } else {
        classnames = [classes.hide].join(" ");
    }

    return (
        <div className={classnames}>
            <StripePaymentAction ref={stripePaymentAction} />
            
            <form onSubmit={handleSubmit}>
                <PaymentElement />

                <Button type="submit" disabled={!stripe}>
                    <span>{t("pay_with_stripe")}</span>
                </Button>
            </form>
        </div>
    );
}