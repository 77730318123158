
import { useRef } from "react";
import { OrderStateAction, OrderStateRef } from "../../actions/OrderStateAction";
import { CartSummary } from "../../components/CartSummary";
import { CheckoutAccordion } from "../../components/CheckoutAccordion";
import { useActiveCustomer, useActiveOrder } from "../../services";
import { useTranslation } from "react-i18next";

export default function Checkout() {

    const { t } = useTranslation("checkout");

    const { order } = useActiveOrder();

    const orderStateAction = useRef<OrderStateRef>(null);

    const { customer } = useActiveCustomer();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const attemptChangeOrderState = (state: string) => {
        if (order?.state !== state) {
            orderStateAction.current?.setOrderState(state);
        }
    }

    return (
        <>
            <OrderStateAction ref={orderStateAction} onCompleted={(result) => {
                // console.log(result);
            }} />

            <div className="grid">
                <div className="row">
                    <div className="col grow-3 ignore">
                        <h2>{t("title")}</h2>
                        <CheckoutAccordion customer={customer} />
                    </div>
                    <div className="col grow-1 ignore">
                        <h2>{t("summary")}</h2>
                        <CartSummary {...{order, customer}} />
                    </div>
                </div>
            </div>
        </>
    );
}
