import { useTranslation } from "react-i18next";
import { changesSV, bespokeSV, changesEN, bespokeEN } from "./services"
import tailoringPatterns from "./tailoring_patterns.jpeg";
import classes from "./Services.module.scss";

type PriceItem = {
    title: string,
    price: string
};

const getCookieValue = (name: string) => (
    document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || ''
);

export default function Services() {

    const { t } = useTranslation("services");

    const languageValue = getCookieValue("language") ? getCookieValue("language") : "sv";

    let changes, bespoke;

    switch(languageValue) {
        case "EN":
        case "en":
            changes = changesEN;
            bespoke = bespokeEN;
            break;
        case "SV":
        case "sv":
        default:
            changes = changesSV;
            bespoke = bespokeSV;
            break;
    }

    return (
        <>
            <div className="row">
                <div className="sixteen columns">
                    <h1 className="pagetitle">{t("services_title")}</h1>
                </div>
            </div>
            <div className="row">
                <div className="column">
                    <img className={classes.image} src={tailoringPatterns} alt="Skräddarmönster och provdocka"/>
                </div>
            </div>
            <div className="row">
                <div className="sixteen columns">
                    <p>{t("instructions")}</p>
                    
                    <p>{t("instructions2")}</p>
                </div>
            </div>

            <div className="row">
                <div className="one-half column">
                    <h3 className={classes.heading}>{t("changes")}</h3>
                    <table className={classes.pristabell}>
                        <thead />
                        <tbody>
                            {changes.map(({ title, price }: PriceItem, index) => (
                                <tr key={`changes-${index}`}>
                                    <td>{title}</td><td className="right">{price}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="one-half column">
                    <h3 className={classes.heading}>{t("bespoke")}</h3>
                    <table className={classes.pristabell}>
                        <thead />
                        <tbody>
                            {bespoke.map(({ title, price }: PriceItem, index) => (
                                <tr key={`bespoke-${index}`}>
                                    <td>{title}</td><td className="right">{price}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

        </>
    );
}
