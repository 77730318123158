import { PiBasketThin } from 'react-icons/pi';
import { Order } from '../../gql/graphql';
import { useMediaQuery } from '@uidotdev/usehooks';
import { useDrawers } from '../../services/drawers';
import { useActiveOrder } from '../../services';

type CartLinkProps = {
};

const orderHasItems = (order: Order | undefined) => {
    if (order) {
        return order.lines.length > 0;
    }
    return false;
}

export default function CartLink(props: CartLinkProps) {

    const activeOrder = useActiveOrder();

    const isMobile = useMediaQuery("only screen and (max-width : 768px)");

    const drawers = useDrawers();

    let hasItems = orderHasItems(activeOrder.order);

    return (
        <>
            <PiBasketThin size={isMobile ? "1.8em" : ""} fill={hasItems ? "#C70039" : undefined} onClick={() => {
                drawers.openCart?.();
            }}/>
        </>
    );
}
