import { useEffect } from "react";
import classes from "./Backdrop.module.scss";
import { useDrawers } from "../../drawers.hook";

type Props = {
    open: boolean;
};

export default function Backdrop({ open }: Props) {
    const drawers = useDrawers();

    useEffect(() => {
        if (open) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "unset" 
        }
    }, [open]);

    return (
        <>
            { open && <div className={classes.backdrop} onClick={() => {
                drawers.closeCart?.();
                drawers.closeNav?.();
            }}/>}
        </>
    );
}
