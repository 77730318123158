import map from './veras_map.png';
import classes from './Hitta.module.scss';
import { useTranslation } from 'react-i18next';

// const columnClasses = ["col", "classes.text"].join(" ");

export default function Hitta() {

    const { t } = useTranslation("find");

    const heading = t("heading");
    const address = t("address");
    const addresslines = address.split("\n");
    
    return (
        <>
            <div className="row">
                <div className="sixteen columns">
                    <h1 className="pagetitle">{t("title")}</h1>
                </div>
            </div>

            <div className="row">
                <div className="one-half column">
                    <img className={classes.image} src={map} alt={t("image_alt")} />
                </div>
                <div className="one-half column">
                    <h3>{heading}</h3>
                    
                    <p>{t("visiting_address")}</p>

                    {addresslines.map((text: string, index: number) => (
                        <p style={index < addresslines.length - 1 ? { marginBottom: '0' } : {}} key={`find-${index}`}>{text}</p>
                    ))}

                    <p>{t("contact_before_visit")}</p>
                </div>
            </div>

        </>
    );
}
