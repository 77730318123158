import { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { ActiveOrderAction, ActiveOrderRef } from "../../actions/ActiveOrderAction";
import { Order } from "../../gql/graphql";
import { ActiveOrderContext } from "./active-order.context";

type Props = {
    children: ReactNode
}

export function ActiveOrderProvider({ children }: Props) {

    const activeOrderAction = useRef<ActiveOrderRef>(null);

    const [order, setOrder] = useState<Order | undefined>(undefined);

    const contextValue = useMemo(() => ({ order }), [order]);

    useEffect(() => {
        activeOrderAction.current?.get();
    }, [activeOrderAction]);

    return (
        <>
            <ActiveOrderContext.Provider value={contextValue}>
                <ActiveOrderAction ref={activeOrderAction} onCompleted={(order) => {
                    setOrder(order);
                }}/>
                {children}
            </ActiveOrderContext.Provider>
        </>
    );
}
