/**
 * This is a singleton to ensure we only instantiate Stripe once.
 */
import { Stripe, loadStripe } from '@stripe/stripe-js';

let stripePromise: Promise<Stripe | null>;

const publishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || "pk_test_...";

const getStripe = () => {
    if (!stripePromise) {
        stripePromise = loadStripe(publishableKey);
    }
    return stripePromise;
};

export default getStripe;