import { useRef, useState } from "react";
import { AccordionItem, AccordionButtons, AccordionItemInterface } from "../../Accordion";
import AddressInputForm, { FormRef, FormType } from "./AddressInputForm";
import { useTranslation } from "react-i18next";
import { ActiveOrderResult, Customer, Order } from "../../../gql/graphql";
import { AccordionButtonInterface } from "../../Accordion/AccordionButtons";
import { useActiveOrder } from "../../../services/active-order";
import { useToast } from "../../../services/toasts";

type Props = {
    customer?: Customer | undefined;
};

const filterResults = (results: Array<ActiveOrderResult | undefined>) => results.filter((result): result is ActiveOrderResult => (!!result));

const PICK_UP_IN_RYDAL = "hamta-i-rydal";

const canSkipShippingAddress = (order: Order | undefined) : boolean => {
    if (order === undefined) {
        return false;
    }

    if (order.shippingLines === undefined || order.shippingLines.length === 0) {
        // If no shipping lines, we can't decide to skip...
        return false;
    }

    return order.shippingLines.map((shippingLine) => (
        shippingLine.shippingMethod.code === PICK_UP_IN_RYDAL
    )).includes(true);
}

export default function AddressRow({
    onPrevious, onNext, onOpened, onClosed,
    customer,
    ...rest
}: AccordionButtonInterface & AccordionItemInterface & Props) {
    const { t } = useTranslation("checkout");

    const { order } = useActiveOrder();

    const shippingForm = useRef<FormRef>(null);
    const billingForm = useRef<FormRef>(null);

    const [shippingReady, setShippingReady] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [billingReady, setBillingReady] = useState(false);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [useBillingAddress, setUseBillingAddress] = useState<boolean>(false);

    // const handleUseBillingAddress = (evt: React.ChangeEvent<HTMLInputElement>): void => {
    //     setUseBillingAddress(evt.currentTarget.checked);
    // };

    // const billingAddress = [
    //     [
    //         {type: "checkbox", name: 'useBillingAddress', label: t("use_billing_address"), onChange: handleUseBillingAddress, checked: useBillingAddress},
    //     ]
    // ];

    const toast = useToast();

    // If the type of shipping is "pick-up" then we can allow skipping the
    // address input.
    const canSkipAddress = canSkipShippingAddress(order);

    const isReady = canSkipAddress || (shippingReady && (useBillingAddress ? billingReady : true));

    return (
        <AccordionItem
            title={t("enter_shipping_and_billing_address")}
            onOpened={onOpened}
            onClosed={onClosed}
            {...rest}>
            <h4>{t("shipping_address")}</h4>
            {(canSkipAddress === false) && <p>{t("shipping_address_text")}</p>}
            {(canSkipAddress === true) && <p>{t("shipping_address_text_two")}</p>}

            {(canSkipAddress === false) && <AddressInputForm {...{formType: FormType.SHIPPING, customer, ref: shippingForm}} onFormReady={(ready) => {
                setShippingReady(ready);
            }} />}

            {/* {renderForm(billingAddress)} */}

            {/* {useBillingAddress && <>
                <h4>{t("billing_address")}</h4>
                <AddressInputForm {...{formType: FormType.BILLING, customer, ref: billingForm}} onFormReady={(ready) => {
                    setBillingReady(ready);
                }} />
            </>} */}
            <AccordionButtons
                isReady={isReady}
                onPrevious={onPrevious}
                onNext={() => {
                    if (canSkipAddress) {
                        onNext?.();
                    } else {
                        Promise.all([
                            shippingForm.current?.submit(),
                            billingForm.current?.submit()
                        ]).then((results) => {
                            if (filterResults(results).length === (useBillingAddress ? 2 : 1)) {
                                onNext?.();
                            }
                        }).catch((reason) => {
                            toast.open(reason);
                        });
                    }
                }} />
        </AccordionItem>
    );
}
