import { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { PublicDataAction, PublicDataRef } from "../../actions/PublicDataAction";
import { Toast, ToastType } from "./types";
import { ToastContext } from "./toast.context";
import { ToastComponent } from "./components/ToastComponent";

const generateUid = () => {
    let first = (Math.random() * 46656) | 0;
    let second = (Math.random() * 46656) | 0;
    return `000${first.toString(36)}`.slice(-3) + `000${second.toString(36)}`.slice(-3);
}

type Props = {
    children: ReactNode
}

export function ToastProvider({ children }: Props) {

    const publicDataAction = useRef<PublicDataRef>(null);

    const [loaded, setLoaded] = useState(false);

    const [toasts, setToasts] = useState<Toast[]>([]);

    const [timeout, setTimeout] = useState(5);

    const open = (content: string, type?: ToastType) => 
        setToasts((currentToasts: Toast[]) => [
            ...currentToasts,
            { id: generateUid(), content, type }
        ]);

    const close = (id: string) => 
        setToasts((currentToasts: Toast[]) => 
            currentToasts.filter((toast) => toast.id !== id)
        );
    
    const contextValue = useMemo(() => ({ open, close }), []);

    useEffect(() => {
        if (!loaded) {
            publicDataAction.current?.get().then((result) => {
                const toastTimeout = result.toastTimeout ? result.toastTimeout : 2;
                setTimeout(toastTimeout);
            });
            setLoaded(true);
        }
    }, [loaded]);

    return (
        <>
            <PublicDataAction ref={publicDataAction} />
            
            <ToastContext.Provider value={contextValue}>
                {children}
                {createPortal(
                    <div style={{
                        position: 'fixed',
                        bottom: '20px',
                        right: '20px'
                    }}>
                        {toasts.map(({ id, content, type }) => (
                            <ToastComponent key={id} close={() => close(id)} timeout={timeout} type={type}>
                                {content}
                            </ToastComponent>
                        ))}
                    </div>,
                    document.body
                )}
            </ToastContext.Provider>
        </>
    );
}
