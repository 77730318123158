import { ReactNode, useEffect, useMemo, useState } from "react";
import { LoadingContext } from "./loading.context";
import { NetworkStatus, UseApolloNetworkStatusOptions } from "react-apollo-network-status/dist/src/useApolloNetworkStatus";

type Props = {
    useStatus: (options?: UseApolloNetworkStatusOptions | undefined) => NetworkStatus;
    children: ReactNode;
}

export function LoadingProvider({ useStatus, children }: Props) {

    const status = useStatus();

    const [isLoading, setIsLoading] = useState(false);
    
    const contextValue = useMemo(() => ({ isLoading }), [isLoading]);

    useEffect(() => {
        if (status.numPendingQueries > 0) {
            setIsLoading(true);
        } else {
            setIsLoading(false);
        }
    }, [status.numPendingQueries]);

    return (
        <>
            <LoadingContext.Provider value={contextValue}>
                {children}
            </LoadingContext.Provider>
        </>
    );
}
