import { ApolloQueryResult, FetchResult, useLazyQuery } from "@apollo/client";
import React, { Ref, useImperativeHandle } from "react";
import { StockLevelQuery } from "./StockLevelQuery";

type Props = {
    onCompleted: (stockLevel: number) => void;
};

export interface StockLevelRef {
    get: (variantId: string) => Promise<FetchResult<{getStockLevel: number}>>,
    resetStore: () => Promise<ApolloQueryResult<any>[] | null>
}

function StockLevelAction (
    { onCompleted }: Props,
    ref: Ref<StockLevelRef>
) {
    const [query, { client }] = useLazyQuery<{getStockLevel: number}, {productVariantId: string}>(StockLevelQuery, {
        onCompleted: ({ getStockLevel }) => {
            onCompleted(getStockLevel);
        }
    });

    const get = (variantId: string): Promise<FetchResult<{getStockLevel: number}>> => query({ variables: { productVariantId: variantId } });

    const resetStore = () => (
        client.resetStore()
    );

    useImperativeHandle(ref, () => ({ get, resetStore }));

    return <></>;
}

export default React.forwardRef(StockLevelAction);
