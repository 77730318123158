import { initializeApp } from "firebase/app";

const IS_FIREBASE_DEV: boolean = process.env.REACT_APP_FIREBASE_ENV === 'dev';

const productionConfig = {
    apiKey: "AIzaSyDL4lf4zoLCvTB_ekfcu8HoV800SwdIsdM",
    authDomain: "veras-atelje-d4fd2.firebaseapp.com",
    projectId: "veras-atelje-d4fd2",
    storageBucket: "veras-atelje-d4fd2.firebasestorage.app",
    messagingSenderId: "448247020567",
    appId: "1:448247020567:web:9f623c50aee52e566c3052",
    measurementId: "G-1H2NB924Z7"
  };

const developmentConfig = {
    apiKey: "AIzaSyCgfGkubxVozsg-NJGbOvd2syv-M515YC0",
    authDomain: "veras-atelje-beta.firebaseapp.com",
    projectId: "veras-atelje-beta",
    storageBucket: "veras-atelje-beta.firebasestorage.app",
    messagingSenderId: "370560671831",
    appId: "1:370560671831:web:b47bef0e8de0cd52b48312",
    measurementId: "G-FT88QPNL0G"
};

console.log(`IS_FIREBASE_DEV = ${IS_FIREBASE_DEV}`)

export const firebaseApp = initializeApp(IS_FIREBASE_DEV ? developmentConfig : productionConfig);
