import { useRef, useState } from "react";
import { AccordionButtons, AccordionItem, AccordionItemInterface } from "../../Accordion";
import GuestCustomerForm, { FormRef } from "./GuestCustomerForm";
import { CommonRowProps } from "..";
import { AlreadyLoggedInError, EmailAddressConflictError, GuestCheckoutError, NoActiveOrderError } from "../../../gql/graphql";
import { useTranslation } from "react-i18next";
import { AccordionButtonInterface } from "../../Accordion/AccordionButtons";
import { useToast } from "../../../services/toasts";

export default function CustomerRow({ 
    onPrevious, onNext, onOpened, onClosed, 
    orderId,
    ...rest
}: AccordionButtonInterface & AccordionItemInterface & CommonRowProps) {
    
    const { t } = useTranslation("checkout");

    const form = useRef<FormRef>(null);

    const [ready, setReady] = useState(false);

    const toast = useToast();

    return (
        <AccordionItem title={t("contact_details")} onOpened={onOpened} onClosed={onClosed} {...rest}>
            <h4>{t("enter_name_and_email")}</h4>
            <p>{t("contact_details_text")}</p>
            <GuestCustomerForm {...{orderId, ref: form}} onFormReady={(ready) => {
                setReady(ready);
            }} />

            <AccordionButtons
                isReady={ready}
                onNext={() => {
                    form.current?.submit().then((result) => {
                        switch (result.__typename) {
                            case "Order": {
                                onNext?.();
                                break;
                            }
                            case "AlreadyLoggedInError":
                                toast.open((result as AlreadyLoggedInError).message);
                                break;
                            case "EmailAddressConflictError":
                                toast.open((result as EmailAddressConflictError).message);
                                break;
                            case "GuestCheckoutError":
                                toast.open((result as GuestCheckoutError).message);
                                break;
                            case "NoActiveOrderError":
                                toast.open((result as NoActiveOrderError).message);
                                break;
                        }
                    }).catch((reason) => {
                        toast.open(reason);
                    });
                }} />
        </AccordionItem>
    );
}
