import { gql } from "@apollo/client";

export const TypeQuery = gql`
query Search($input: SearchInput!) {
    search(input: $input) {
        items {
            slug
            productName
            inStock
            priceWithTax {
                ... on PriceRange {
                    min
                }
                ... on SinglePrice {
                    value
                }
            }
            productAsset {
                preview
            }
        }
        totalItems
    }
}
`
