import { NavigationLink } from '.';
import { useTranslation } from 'react-i18next';
import classes from './navigation.module.scss';

export default function Navigation() {
    const { t } = useTranslation("navigation");

    return (
        <nav className={classes.nav}>
            {/* <NavigationLink to='/'>{t("readymade")}</NavigationLink> */}
            <NavigationLink to='/made-to-order'>{t("made_to_order")}</NavigationLink>
            {/* <NavigationLink to='/gallery'>{t("gallery")}</NavigationLink> */}
            <NavigationLink end to='/'>{t("about")}</NavigationLink>
            <NavigationLink to='/services'>{t("services")}</NavigationLink>
            <NavigationLink to='/hitta'>{t("find")}</NavigationLink>
        </nav>
    );
}
