import { useRef, useState } from "react";
import { CustomerAddressAction, CustomerAddressRef } from "../../actions/CustomerAddressAction";
import AddressForm from "../../components/AddressForm/AddressForm";
import { OrdersList } from "../../components/OrdersList";
import { UpdateCustomerForm } from "../../components/UpdateCustomerForm";
import { useTranslation } from "react-i18next";
import { useActiveCustomer } from "../../services";
import { Button } from "../../components/Button";
import { AccountAction, AccountRef } from "../../actions/AccountAction";
import { useNavigate } from "react-router-dom";

const FRONT_PAGE = "/";

export default function CustomerPage() {

    const { t } = useTranslation("user");

    const navigate = useNavigate();

    const [creatingAddress, setCreatingAddress] = useState(false);
    const [loggingOut, setLoggingOut] = useState(false);

    const { customer } = useActiveCustomer();

    const customerAddressAction = useRef<CustomerAddressRef>(null);

    const accountAction = useRef<AccountRef>(null);
    
    const { firstName, lastName, addresses } = customer || {};

    const deleteAddressHandler = (id: string) => {
        customerAddressAction.current?.delete(id);
    }

    const createAddressHandler = () => {
        setCreatingAddress(true);
    
        customerAddressAction.current?.create("", "SE").then(() => {
            setCreatingAddress(false);
            customerAddressAction.current?.resetStore();
        });
    };

    const logoutHandler = () => {
        setLoggingOut(true);

        accountAction.current?.logout().then(() => {
            setLoggingOut(false);
            accountAction.current?.resetStore();
            navigate(FRONT_PAGE);
        })
    };

    return (
        <>
            <AccountAction ref={accountAction} />

            <CustomerAddressAction ref={customerAddressAction} />

            <div className="grid-container">
                {customer && <>
                    <div className="row">
                        <h2 className="pagetitle">{t("welcome")} {firstName} {lastName}</h2>
                    </div>
                    <div className="row">
                        <p>{t("description")}</p>
                    </div>
                    <div className="row">
                        <div className="one-half column">
                            <UpdateCustomerForm {...{customer: customer}} />
                        </div>
                        <div className="one-half column">
                            {addresses?.map((address, index) => (
                                <AddressForm key={address.id} address={address} onDelete={deleteAddressHandler} />
                            ))}

                            <div style={{margin: "20px"}}>
                                <Button
                                    fullWidth={true}
                                    loading={creatingAddress}
                                    onClick={createAddressHandler}>{creatingAddress ? t("creating") : t("create_address")}</Button>
                            </div>
                        </div>
                        <div className="one-half column">
                            <OrdersList {...{customer}} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="one-half column">
                            <div style={{margin: "20px"}}>
                                <Button
                                    fullWidth={true}
                                    loading={loggingOut}
                                    onClick={logoutHandler}>{t("logout")}</Button>
                            </div>
                        </div>
                    </div>
                </>}
            </div>
        </>
    );
}
