import classes from "./Privacypolicy.module.scss";

export default function Privacypolicy() {
    return (
        <>
            <div className="row">
                <div className="sixteen columns">
                    <p><i>All GDPR/legal pages on my website are written in Swedish only.</i></p>

                    <h2>Så här hanterar Veras Ateljé dina personuppgifter</h2>
                    <p>Jag, Vera, följer dataskyddsförordningen GDPR för lagring av personuppgifter. För att du skall kunna handla i min butik behöver jag hantera dina personuppgifter. Till exempel behöver jag veta ditt namn, och din address för att kunna skicka en vara till dig. För att enkelt kunna kommunicera med dig angående dina ordrar behöver jag t.ex. spara din e-post.</p>
                    <h2>Vilka personuppgifter samlar Veras Ateljé in?</h2>
                    <p>Informationen jag sparar om dig innefattar:</p>
                    <ul>
                        <li>Namn</li>
                        <li>Leveransadress/er</li>
                        <li>Faktureringsadress/er (när du väljer att ange en sådan)</li>
                        <li>E-Post</li>
                        <li>Telefonnummer</li>
                        <li>Kundnummer hos Stripe (betalningshanterare)</li>
                        <li>Din köphistorik hos mig</li>
                    </ul>
                    <h2>Vad gör Veras Ateljé med dina personuppgifter?</h2>
                    <p>Dina personuppgifter används endast för att kunna driva webshoppen och sälja varor till dig.</p>
                    <h2>Sprider Veras Ateljé dina personuppgifter till tredje part?</h2>
                    <p>Nej, jag sprider inte dina uppgifter till tredje part, annat än när behov uppstår, som t.ex. vid anlitande av speditör för att kunna skicka paket till dig (i mitt fall använder jag endast Postnord), eller vid betalningshanteringen (jag använder endast Stripe). Och då delar jag endast de uppgifter som krävs för att genomföra betalning, eller skicka paket.</p>
                    <p>Jag använder Firebase för att analysera viss trafik du genererar för att bättre förstå hur jag bör förbättra din upplevelse. Jag använder ingen slags marknadsföringstjänst för att öka min omsättning med hjälp av dina privata uppgifter. Din trygghet är högsta prioritet för mig, och jag vill att du känner dig säker i att anlita mig.</p>
                    <h2>Använder Veras Ateljé dina uppgifter i marknadsföringssyfte?</h2>
                    <p>Nej. Jag använder inte de uppgifter du sparar hos mig i marknadsföringssyfte; varken mot dig direkt eller via tredje part.</p>
                    <h2>Hur länge sparar Veras Ateljé dina personuppgifter?</h2>
                    <p>Jag sparar dina uppgifter så länge som jag behöver - eller tills dess att du önskar att de tas bort från mitt system. Du har alltid möjligheten att avregistrera dig från systemet, varpå all data som kan tas bort, kommer tas bort. Viss data som är kopplad till ordrar kommer dock inte tas bort då jag behöver dessa för att kunna bokföra inkomst och utgifter på ett effektivt och säkert sätt.</p>
                    <p>Du har alltid rätt att:</p>
                    <ul>
                        <li>Veta vilka personuppgifter jag sparar, och varför. Det finner du förklarat på denna sida.</li>
                        <li>Få tillgång till de uppgifter jag har om dig. Du hittar dem alltid på din personliga kundsida.</li>
                        <li>Korrigera de uppgifter som jag sparat, om de är felaktiga. Det kan du göra på din personliga kundsida.</li>
                        <li>Klaga till <a className={classes.link} href="https://www.imy.se/">Integritetsskyddsmyndigheten</a> (f.d. datainspektionen) om du anser att jag hanterar dina personuppgifter på ett felaktigt sätt.</li>
                    </ul>
                    <p>Så länge det inte strider mot några lagar har du också rätt att:</p>
                    <ul>
                        <li>Begränsa hur jag använder dina personuppgifter.</li>
                        <li>Invända om jag behandlar dina personuppgifter utan ditt samtycke.</li>
                        <li>Begära att jag raderar uppgifter som du tidigare samtyckt till att jag sparar.</li>
                    </ul>
                    <p>Du är alltid välkommen att kontakta mig på <a className={classes.link} href="mailto:butiken@verasatelje.se">butiken@verasatelje.se</a> för att diskutera den data jag sparar om dig och begära att jag tar bort data från mitt system.</p>
                    <h2>På vilken laglig grund behandlar Veras Ateljé dina personuppgifter?</h2>
                    <p>Den lagliga grunden för att jag sparar din data är samtycke eller intresseavvägning. Samtycke används när du fyller i ett formulär, eller skapar en order.</p>
                </div>
            </div>
        </>
    );
}
