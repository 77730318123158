import { NavLink } from 'react-router-dom';
import classes from './navigationlink.module.scss';

type NavigationLinkProps = {
    end?: boolean;
    to: string;
    children: JSX.Element | string;
};

const handleNavlinkClass = ({ isActive, isPending }: { isActive: boolean, isPending: boolean }) => (
    isPending ? classes.navlinkPending : isActive ? classes.navlinkActive : classes.navlink
);

export default function NavigationLink(props: NavigationLinkProps) {
    const { to, children } = props;

    return (
        <>
            <NavLink className={handleNavlinkClass} to={to} end>
                {children}
            </NavLink>
        </>
    );
}