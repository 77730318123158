import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { OrderAction, OrderRef } from "../../../actions/OrderAction";
import { useTranslation } from "react-i18next";
// import { Namespace, TFunction } from "i18next";
import { Address, Order, OrderAddress } from "../../../gql/graphql";
import { CartSummary } from "../../../components/CartSummary";
import classes from "./CartDrawer.module.scss";
import { getSize, priceWithDecimals, printAddress } from "../../../components/helpers";
import { Maybe } from "graphql/jsutils/Maybe";

/*
const generateShippingText = (order: Order | undefined, t: TFunction<Namespace, undefined>) => {
    var text = "";

    if (!order) {
        return "";
    }

    text = t("produce_order");

    if (order.shipping <= 0) {
        text = `${text}${t("ready_for_pickup")}`;
    } else {
        text = `${text}${t("ready_for_shipping")}`;
    }

    return text;
}
*/

/*
const getCookieValue = (name: string) => (
    document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || ''
);

const languageValue = getCookieValue("language") ? getCookieValue("language") : "sv";

const renderOrderDate = (dateTime: Date) => (
    <time dateTime={dateTime.toString()}>{dateTime.toLocaleDateString(languageValue)}</time>
);
*/

const hasBillingAddress = (address?: Maybe<OrderAddress>): boolean => {
    if (!address) {
        return false;
    }

    if (!address.city && !address.streetLine1 && !address.postalCode) {
        return false;
    }

    return true;
}

export default function OrderPage() {

    const { t } = useTranslation("orders");

    const { orderCode } = useParams();

    const orderAction = useRef<OrderRef>(null);

    const [order, setOrder] = useState<Order | undefined>(undefined);

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (orderCode && (!loaded || !orderCode)) {
            orderAction.current?.byCode(orderCode);
            setLoaded(true);
        }
    }, [loaded, setLoaded, orderAction, orderCode]);
    
    const shouldShip: boolean = (order && order.shipping > 0) || false;

    // TODO: Get the data from "global data"... from a "hook"...
    const verasAddress = {
        fullName: "Veras Ateljé",
        streetLine1: "Boråsvägen 237",
        postalCode: "511 70",
        city: "Rydal"
    } as Address;

    return (
        <>
            {!order && <p>{t("loading")}</p>}

            <OrderAction ref={orderAction} onOrder={(order) => {
                setOrder(order);
            }} />

            <div className="grid">
                <div className="row">
                    <div className="col grow-1 ignore">

                        <h3>{t("order_id")}: {order?.code}</h3>

                        {shouldShip ? <h3 style={{marginBottom: "0.5em"}}>{t("send_to")}</h3> : <></>}
                        {!shouldShip ? <h3 style={{marginBottom: "0.5em"}}>{t("pickup_by")}</h3> : <></>}
                        {order?.shippingAddress && printAddress(order?.shippingAddress)}
                        
                        {!shouldShip ? <h3 style={{marginBottom: "0.5em"}}>{t("pickup_at")}</h3> : <></>}
                        {!shouldShip ? printAddress(verasAddress) : <></> }

                        {hasBillingAddress(order?.billingAddress) && <h3 style={{marginBottom: "0.5em"}}>{t("bill_to")}</h3>}
                        {order?.billingAddress && printAddress(order?.billingAddress)}

                        <h3>{t("summary")}</h3>

                        <div className={classes.content}>
                            {order && order.lines.map((line) => (
                                <div className={classes.cartline} key={`order-line-${line.id}`}>
                                    {line.featuredAsset && <img className={classes.cartlineimg} src={`${line.featuredAsset.preview}?preset=thumb`} alt={line.productVariant.name} />}
                                
                                    <div className={classes.cartlinedetails}>
                                        <p>{line.productVariant.name}</p>
                                        <p>{t("size")}: {getSize(line.productVariant.options)}</p>
                                        <p>{t("quantity")}: {line.quantity}</p>
                                        <p>{t("price")}: {priceWithDecimals(line.linePriceWithTax, t("unit"))}</p>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <CartSummary order={order} />

                        <p>{t("questions")}</p>

                        <p>{t("regards")}</p>
                        <h2>Vera Göransson</h2>
                    </div>
                </div>
            </div>
        </>
    );
}
