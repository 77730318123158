import { createContext } from 'react';
import { Analytics, EventParams } from "firebase/analytics";

export interface AnalyticsContextInterface {
    analytics: Analytics,
    event: (event: string, params?: EventParams) => void
}

const defaultState = {};

export const AnalyticsContext = createContext<Partial<AnalyticsContextInterface>>(defaultState);
