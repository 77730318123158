import { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { ActiveCustomerAction, ActiveCustomerRef } from "../../actions/ActiveCustomerAction";
import { Customer } from "../../gql/graphql";
import { ActiveCustomerContext, ActiveCustomerContextInterface } from "./active-customer.context";

type Props = {
    children: ReactNode
}

export function ActiveCustomerProvider({ children }: Props) {

    const activeCustomerAction = useRef<ActiveCustomerRef>(null);

    const [customer, setCustomer] = useState<Customer | undefined>(undefined);
    const [isLoading, setLoading] = useState(false);
    const [hasLoaded, setHasLoaded] = useState(false);
    const [error, setError] = useState<string | undefined>();

    const clearActiveCustomer = () => {
        setCustomer(undefined);
        setLoading(false);
        setHasLoaded(false);
        setError(undefined);
    };

    const fetch = () => {
        activeCustomerAction.current?.get()
            .then((activeCustomer) => {
                if (activeCustomer) {
                    setCustomer(activeCustomer);
                } else {
                    setCustomer(undefined);
                }
                setError(undefined);
            })
            .catch((err) => {
                setError(err);
            })
            .finally(() => {
                setLoading(false);
                setHasLoaded(true);
            })
    };

    const contextValue = useMemo<ActiveCustomerContextInterface>(() => ({ customer, isLoading, hasLoaded, error, clearActiveCustomer, fetch }), [customer, isLoading, hasLoaded, error]);

    useEffect(() => {
        activeCustomerAction.current?.get()
            .then((activeCustomer) => {
                if (activeCustomer) {
                    setCustomer(activeCustomer);
                } else {
                    setCustomer(undefined);
                }
                setError(undefined);
            })
            .catch((err) => {
                setError(err);
            })
            .finally(() => {
                setLoading(false);
                setHasLoaded(true);
            })
    }, [activeCustomerAction]);

    return (
        <>
            <ActiveCustomerContext.Provider value={contextValue}>
                <ActiveCustomerAction ref={activeCustomerAction} />
                {children}
            </ActiveCustomerContext.Provider>
        </>
    );
}
