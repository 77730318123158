import React, { Ref } from "react";
import Size from "./Size";
import { ProductVariant } from "../../../gql/graphql";
import ProductVariantSelector, { ProductVariantSelectorRef } from "../ProductVariantSelector";
import { useAnalytics } from "../../../services";

type Params = {
    variants: Array<ProductVariant> | undefined;
    onSelected: (variant: ProductVariant | undefined) => void;
}

function SizeSelector(
    { variants, onSelected }: Params,
    ref: Ref<ProductVariantSelectorRef>
) {
    const { event } = useAnalytics();

    return (
        <>
            <ProductVariantSelector
                ref={ref}
                PVComponent={Size}
                variants={variants}
                onSelected={(variant) => {
                    event?.("variant-size-selected", { size: variant?.name })
                    onSelected(variant);
                }} />
        </>
    )
}

export default React.forwardRef(SizeSelector);
