/* eslint-disable @typescript-eslint/no-unused-vars */

import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { PiXThin } from "react-icons/pi";
import { useSwipeable } from "react-swipeable";
import { useEffect, useRef, useState } from "react";
import { CiFacebook, CiInstagram } from "react-icons/ci";
import { RiPinterestLine } from "react-icons/ri";
import { BiLogoEtsy } from "react-icons/bi";
import classes from "./NavDrawer.module.scss";
import { useDrawers } from "../../drawers.hook";
import { PublicDataAction, PublicDataRef } from "../../../../actions/PublicDataAction";

type Props = {
    open: boolean
};

export default function NavDrawer({ open }: Props) {
    const { t } = useTranslation("navigation");

    const globalSettingsAction = useRef<PublicDataRef>(null);

    const [loaded, setLoaded] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [loading, setLoading] = useState(false);

    const [affiliates, setAffiliates] = useState<Array<string> | null>(null);
    const [affiliatesTitles, setAffiliatesTitles] = useState<Array<string> | null>(null);

    const [instagramUri, setInstagramUri] = useState<string | null>(null);
    const [facebookUri, setFacebookUri] = useState<string | null>(null);
    const [pinterestUri, setPinterestUri] = useState<string | null>(null);
    const [etsyUri, setEtsyUri] = useState<string | null>(null);

    const { closeNav } = useDrawers();

    const swipeable = useSwipeable({
        onSwipedLeft: () => { closeNav?.(); }
    });

    useEffect(() => {
        if (!loaded) {
            setLoading(true);
            globalSettingsAction.current?.get().then((result) => {
                setLoading(false);
                const {
                    instagramUri,
                    facebookUri,
                    pinterestUri,
                    etsyUri,

                    affiliates,
                    affiliates_titles
                } = result;

                setInstagramUri(instagramUri);
                setFacebookUri(facebookUri);
                setPinterestUri(pinterestUri);
                setEtsyUri(etsyUri);

                setAffiliates(affiliates);
                setAffiliatesTitles(affiliates_titles);
            });
            setLoaded(true);
        }
    }, [loaded]);

    return (
        <>
            <PublicDataAction ref={globalSettingsAction} />
            
            <div className={[classes.container, (open ? classes.open : "")].join(" ")} {...swipeable}>
                <div className={classes.closeBtn}>
                    <PiXThin size="1.8em" onClick={() => {
                        closeNav?.();
                    }} />
                </div>

                <div className={classes.header}>
                    <h3>Veras Ateljé</h3>
                </div>

                <div className={classes.content}>
                    {/* <NavLink onClick={() => {closeNav?.();}} to='/'>{t("readymade")}</NavLink> */}
                    <NavLink onClick={() => {closeNav?.();}} to='/made-to-order'>{t("made_to_order")}</NavLink>
                    <HR />
                    {/* <NavLink onClick={() => {closeNav?.();}} to='/gallery'>{t("gallery")}</NavLink> */}
                    <NavLink onClick={() => {closeNav?.();}} to='/'>{t("about")}</NavLink>
                    <NavLink onClick={() => {closeNav?.();}} to='/services'>{t("services")}</NavLink>
                    <NavLink onClick={() => {closeNav?.();}} to='/hitta'>{t("find")}</NavLink>
                    <NavLink onClick={() => {closeNav?.();}} to='/privacypolicy'>{t("privacy_policy")}</NavLink>
                    <NavLink onClick={() => {closeNav?.();}} to='/terms'>{t("terms")}</NavLink>
                    <HR />
                    {/* {affiliates && affiliates.map((affiliate, index) => 
                        <a key={`affiliate-${index}`} href={`https://${affiliate}`} target="_blank" rel="noreferrer" itemProp="url">
                            {affiliatesTitles && affiliatesTitles[index] ? affiliatesTitles[index] : affiliate}
                        </a>
                    )}
                    {affiliates && <HR />} */}
                    <div className={classes.socialContainer}>
                        {instagramUri && <a href={`https://${instagramUri}`} target="_blank" rel="noreferrer">
                                <CiInstagram size="2em" />
                            </a>}
                        {facebookUri && <a href={`https://${facebookUri}`} target="_blank" rel="noreferrer">
                                <CiFacebook size="2em" />
                            </a>}
                        {pinterestUri && <a href={`https://${pinterestUri}`} target="_blank" rel="noreferrer">
                                <RiPinterestLine size="2em" />
                            </a>}
                        {etsyUri && <a href="https://www.etsy.com/se-en/shop/VerasAteljeSweden" target="_blank" rel="noreferrer">
                                <BiLogoEtsy size="2em" />
                            </a>}
                    </div>
                </div>
            </div>
        </>
    );
}

const HR = () => {
    return (
        <div className={classes.hr} />
    );
}
