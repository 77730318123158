import { Route, Routes, useLocation } from "react-router-dom";
import { Layout } from "../components/Layout";
import { PageWrapper } from "../components/PageWrapper";
import About from "./about";
import LocationPage from "./location";
import Privacypolicy from "./privacypolicy";
import CustomerPage from "./customer";
import NoMatch from "./nomatch";
import Terms from "./terms";
import Services from "./services";
import MadeToOrder from "./madetoorder";
import ProductPage from "./product/[slug]";
import Verify from "./customer/verify";
import Checkout from "./customer/checkout";
import OrderPage from "./customer/order/[orderCode]";
import ReceiptPage from "./customer/receipt/[orderCode]";
import PasswordResetPage from "./login/passwordReset";
import RequestResetPage from "./login/requestReset";
import LoginPage from "./login";
import RegisterPage from "./register";
import ProtectedRoute from "../components/ProtectedRoute/ProtectedRoute";
import PublicRoute from "../components/PublicRoute/PublicRoute";
import RegisteredPage from "./register/registered";
import Hitta from "./hitta";
import { useAnalytics } from "../services";
import { useEffect } from "react";

const withWrapper = (children: JSX.Element[] | JSX.Element): JSX.Element => (<PageWrapper>{children}</PageWrapper>);

const needAuth = (children: JSX.Element[] | JSX.Element): JSX.Element => (<ProtectedRoute>{children}</ProtectedRoute>);

const noAuth = (children: JSX.Element[] | JSX.Element): JSX.Element => (<PublicRoute>{children}</PublicRoute>);

export default function AppRoutes() {
    const { event } = useAnalytics();
    const location = useLocation();

    useEffect(() => {
        event?.("page-change", { page: location.pathname });
    }, [location, event]);

    return (
        <Routes>
            <Route path="/" element={<Layout />}>
                <Route index element={<PageWrapper><About /></PageWrapper>} />
                <Route path="about" element={withWrapper(<About />)} />
                {/* <Route path="gallery" element={withWrapper(<GalleryPage />)} /> */}
                <Route path="services" element={withWrapper(<Services />)} />
                {/* <Route path="priser" element={withWrapper(<Priser />)} /> */}
                <Route path="location" element={withWrapper(<LocationPage />)} />
                <Route path="privacypolicy" element={withWrapper(<Privacypolicy />)} />
                <Route path="terms" element={withWrapper(<Terms />)} />
                <Route path="hitta" element={withWrapper(<Hitta />)} />

                {/* Routes to "butiken" */}
                {/* <Route path="readymade" element={withWrapper(<ReadyMade />)} /> */}
                <Route path="made-to-order" element={withWrapper(<MadeToOrder />)} />
                <Route path="made-to-order/:slug" element={withWrapper(<ProductPage />)} />

                {/* <Route path="butiken/:slug" element={withWrapper(<CollectionPage />)} />
                <Route path="butiken/:slug/:productSlug" element={withWrapper(<ProductPage />)} /> */}

                <Route path="login" element={noAuth(withWrapper(<LoginPage />))} />
                <Route path="register" element={noAuth(withWrapper(<RegisterPage />))} />
                <Route path="registered" element={noAuth(withWrapper(<RegisteredPage />))} />
                <Route path="password-reset" element={noAuth(withWrapper(<PasswordResetPage />))} />
                <Route path="request-reset" element={noAuth(withWrapper(<RequestResetPage />))} />

                {/* Routes to order handling (no auth) */}
                <Route path="checkout" element={withWrapper(<Checkout />)} />
                <Route path="order/:orderCode" element={withWrapper(<OrderPage />)} />
                <Route path="receipt/:orderCode" element={withWrapper(<ReceiptPage />)} />

                {/* Routes to customer pages */}
                <Route path="customer" element={needAuth(withWrapper(<CustomerPage />))} />
                <Route path="customer/verify" element={noAuth(withWrapper(<Verify />))} />
                {/* <Route path="customer/verify-change" element={noAuth(withWrapper(<VerifyEmailChange />))} /> */}

                {/* Using path="*"" means "match anything", so this route
                    acts like a catch-all for URLs that we don't have explicit
                    routes for. */}
                <Route path="*" element={withWrapper(<NoMatch />)} />
            </Route>
        </Routes>
    );
}