import { useState } from "react";
import { LoginForm } from "../../components/LoginForm";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

const REQUEST_RESET_PAGE: string = "/request-reset";

const CUSTOMER_PAGE = "/customer";

export default function LoginPage() {

    const { t } = useTranslation("user");

    const navigate = useNavigate();

    const [showResetLink, setShowResetLink] = useState(false);

    return (
        <>
            <div className="grid-container">
                <div className="row">
                    <div className="sixteen column">
                        <LoginForm onResult={(success) => {
                            if (!success) {
                                setShowResetLink(true);
                            } else {
                                navigate(CUSTOMER_PAGE);
                            }
                        }} />

                        {showResetLink && <div style={{margin: "20px"}}>
                            <Link to={REQUEST_RESET_PAGE}>
                                <button style={{width: "100%"}}>{t("password_reset_link")}</button>
                            </Link>
                        </div>}
                    </div>
                </div>
                <div className="row">
                    <div className="sixteen column" style={{padding: "0px 18px"}}>
                        <Link to="/register">
                            <button style={{width: "100%"}}>{t("register_page")}</button>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
}
