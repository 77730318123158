import { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { OrderAction, OrderRef } from "../../../actions/OrderAction";
import { CartSummary } from "../../../components/CartSummary";
import classes from "./CartDrawer.module.scss";
import { getSize, priceWithDecimals, printAddress } from "../../../components/helpers";
import { useTranslation } from "react-i18next";
import { Order } from "../../../gql/graphql";
import { useTimeout } from "../../../services";

const PICK_UP_IN_RYDAL = "hamta-i-rydal";

const isString = (value: unknown): value is string => {
    return typeof value === 'string';
}

const isPickUpOrder = (order: Order | undefined): boolean => {
    if (order === undefined) {
        return false;
    }
    if (order.shippingLines.length < 0) {
        return false;
    }
    if (order.shippingLines[0].shippingMethod.code === PICK_UP_IN_RYDAL) {
        return true;
    }

    return false;
}

const isActive = (order: Order | undefined): boolean => {
    if (order === undefined) {
        return false;
    }

    return order.active
}

export default function ReceiptPage() {
    const { t } = useTranslation("receipt");

    const { orderCode } = useParams();

    const orderAction = useRef<OrderRef>(null);

    const [order, setOrder] = useState<Order | undefined>();

    const attemptFetch = () => {
        if (isString(orderCode) && !isActive(order)) {
            orderAction.current?.byCode(orderCode);
        } else {
            setTimeout(attemptFetch, 800);
        }
    };

    useTimeout(() => {
        attemptFetch();
    }, 800);
    
    return (
        <>
            <OrderAction ref={orderAction} onOrder={(order) => {
                setOrder(order);
            }} />

            <div className="grid">
                <div className="row">
                    <div className="col grow-3 ignore">
                        <h2>{t("receipt_title")}</h2>
                        {/* <p>Jag kommer se över din order och påbörja arbetet att sy den omgående, notera att där kan finnas ordrar som skall behandlar innan din.</p>
                        <p>Jag garanterar alltid att din order lämnar min atelje inom två till fyra veckor.</p> */}
                        <p>{t("start_production")}</p>
                        <p>{t("shipping_guarantee")}</p>

                        {order?.active && <p>Din betalning hanteras fortfarande, du kommer få ett e-post meddelande så fort betalningen har hanterats.</p>}

                    </div>
                    <div className="col grow-1 ignore">

                        <h3 style={{marginBottom: "0.5em"}}>{t("ship_to")}</h3>

                        {(!isPickUpOrder(order) && order?.shippingAddress) && printAddress(order?.shippingAddress)}

                        {isPickUpOrder(order) && <p>{t("pickup_at_rydal")}</p>}

                        {/* <h3 style={{marginBottom: "0.5em"}}>{t("bill_to")}</h3> */}

                        {/* {order?.billingAddress && printAddress(order?.billingAddress)} */}

                        <h3>{t("summary")}</h3>

                        <div className={classes.content}>
                            {order && order.lines.map((line) => (
                                <div className={classes.cartline} key={`order-line-${line.id}`}>
                                    {line.featuredAsset && <img className={classes.cartlineimg} src={`${line.featuredAsset.preview}?preset=thumb`} alt={line.productVariant.name} />}
                                
                                    <div className={classes.cartlinedetails}>
                                        <p>{line.productVariant.name}</p>
                                        <p>{t("size")}: {getSize(line.productVariant.options)}</p>
                                        <p>{t("quantity")}: {line.quantity}</p>
                                        <p>{t("price")}: {priceWithDecimals(line.linePriceWithTax, t("unit"))}</p>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <CartSummary order={order} />

                        <p>{t("questions")}</p>

                        <p><i>{t("regards")}</i></p>
                        <h2>Vera Göransson</h2>
                    </div>
                </div>
            </div>
        </>
    );
}
