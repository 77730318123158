import { useEffect, useState } from "react";
import { AccountLink } from "../AccountLink";

import classes from './header.module.scss';
import { Logo } from "../Logo";
import { Navigation } from "../Navigation";
import { CartLink } from "../CartLink";
import { Link } from "react-router-dom";
import { LanguageSelect } from "../LanguageSelect";
import { useApolloClient } from "@apollo/client";
import { useCookies } from "react-cookie";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

export default function Header() {
    const client = useApolloClient();

    const [isShrunk, setShrunk] = useState(false);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [cookies, setCookie, _] = useCookies(['language']);

    const { t } = useTranslation("header");

    useEffect(() => {
        if (cookies.language) {
            i18next.changeLanguage(cookies.language);
        }
    }, [cookies]);

    useEffect(() => {
        const handler = () => {
            setShrunk((isShrunk) => {
                if (!isShrunk && (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20)) {
                    return true;
                }
        
                if (isShrunk && document.body.scrollTop < 4 && document.documentElement.scrollTop < 4) {
                    return false;
                }
        
                return isShrunk;
            });
        };
    
        window.addEventListener("scroll", handler);
        return () => window.removeEventListener("scroll", handler);
    }, [isShrunk]);

    return (
        <div className={classes.header}>
            <div className={classes.logocontainer}>
                <Link to='/' className={classes.logolink}>
                    <Logo />
                </Link>
                <div className={classes.mobiletagline}>
                    <p>{t("veras_tagline")}</p>
                </div>
            </div>
            <div className={classes.headerRight}>
                <div className='header-right-top'>
                </div>
                <div className={classes.headerRightBottom}>
                    <Navigation />
                    <div className={classes.headerRightIcons}>
                        <LanguageSelect defaultCode={cookies.language} onLanguageChanged={(language) => {
                            setCookie("language", language.value);
                            i18next.changeLanguage(language.value);
                            client.cache.reset();
                        }} />
                        
                        <AccountLink />
                        <CartLink />
                    </div>
                </div>
            </div>
        </div>
    );
}
