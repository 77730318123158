import { gql } from "@apollo/client";

export const SearchQuery = gql`
query Search($input: SearchInput!, $slug: String!) {
    search(input: $input) {
        items {
            slug
            productName
            inStock
            productAsset {
                preview
            }
        }
    }
}
`;