import { ReactNode, useEffect, useMemo, useState } from "react";
import { useCookies } from "react-cookie";
import { CookieConsentContext } from "./cookie-consent.context";
import { cookieConsentName } from "./components/ConsentComponent/CookieConsentText";

type Props = {
    children: ReactNode
}

export function CookieConsentProvider({ children }: Props) {

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [cookies, setCookie, __] = useCookies([cookieConsentName]);

    const [consent, setConsent] = useState<Boolean>(cookies.veras_atelje_consent);

    const approve = () => setConsent(true);
    const decline = () => setConsent(false);
    
    const contextValue = useMemo(
        () => ({ consent, approve, decline }),
        [consent]
    );

    useEffect(() => {
        setCookie(cookieConsentName, consent, { sameSite: "lax", secure: true });
    }, [consent, setCookie]);

    return (
        <>
            <CookieConsentContext.Provider value={contextValue}>
                {children}
            </CookieConsentContext.Provider>
        </>
    );
}
