import { ProductVariantProps } from "../ProductVariantSelector";
import classes from "./Size.module.scss";

export default function Size({ id, name, active, stockLevel, options, onSelect }: ProductVariantProps) {
    // const classNames = (active ? [classes.active, classes.container] : [classes.container]).join(" ");

    let classNames = [classes.container];
    if (active) {
        classNames.push(classes.active);
    }
    if (stockLevel === "OUT_OF_STOCK") {
        if (active) {
            classNames.push(classes.outOfStockActive);
        } else {
            classNames.push(classes.outOfStock);
        }
    }

    const handleClick = () => {
        if (id) {
            onSelect?.(id);
        }
    }

    return (
        <div className={classNames.join(" ")} onClick={handleClick}>
            <span>{options[0].code.toUpperCase()}</span>
        </div>
    );
}
