import { useState, useEffect, useRef } from "react";
import { ProductAction, ProductRef } from "../../../actions/ProductAction";
import { PriceTag } from "../../../components/PriceTag";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAnalytics, useLoading } from "../../../services";
import classes from "./productslug.module.scss";
import PreviewSlider from "./PreviewSlider";
import { SocialSharingComponent } from "../../../components/SocialSharingComponent";
import { Asset, Product, ProductVariant } from "../../../gql/graphql";
import { Button } from "../../../components/Button";
import { AddItemToOrderAction, AddItemToOrderRef } from "../../../actions/AddItemToOrderAction";
import { PublicDataAction, PublicDataRef } from "../../../actions/PublicDataAction";
import { StockLevelAction, StockLevelRef } from "../../../actions/StockLevelAction";
import { SizeSelector } from "../../../components/VariantSelectors/SizeSelector";
import { ProductVariantSelectorRef } from "../../../components/VariantSelectors/ProductVariantSelector";
import { useDrawers } from "../../../services/drawers";
import { useToast } from "../../../services/toasts";
import { useConsent } from "../../../services/cookie-consent";

export default function ProductPage() {

    const { consent, approve } = useConsent();

    const { t } = useTranslation("product");

    const { openCart } = useDrawers();

    const toast = useToast();

    const addItemToOrder = useRef<AddItemToOrderRef>(null);
    const globalSettingsAction = useRef<PublicDataRef>(null);
    const stockLevelAction = useRef<StockLevelRef>(null);

    const sizeSelector = useRef<ProductVariantSelectorRef>(null);

    const { isLoading } = useLoading();

    const productAction = useRef<ProductRef>(null);

    const [phoneLoaded, setPhoneLoaded] = useState(false);
    const [phone, setPhone] = useState<string | null>(null);

    const [product, setProduct] = useState<Product | undefined>();

    const [variant, setVariant] = useState<ProductVariant | undefined>(undefined);
    const [variantStock, setVariantStock] = useState<number | undefined>(undefined);

    const [assets, setAssets] = useState<Array<Asset>>([]);

    const { slug } = useParams();

    const { event } = useAnalytics();

    useEffect(() => {
        if (slug) {
            productAction.current?.get(slug);
        }
    }, [slug]);

    useEffect(() => {
        if (product?.assets) {
            setAssets(product.assets);
        }
    }, [product])

    const handleLoadProduct = (products: Array<Product>) => {
        setProduct(products[0]);
    }
    
    const handleSizeSelected = (variant: ProductVariant | undefined) => {
        setVariant(variant);
    }
    
    useEffect(() => {
        if (!phoneLoaded) {
            globalSettingsAction.current?.get().then((result) => {
                const {
                    phone
                } = result;

                setPhone(phone ? phone : null);
            });
            setPhoneLoaded(true);
        }
    }, [phoneLoaded]);

    const [adding, setAdding] = useState(false);

    useEffect(() => {
        if (variant) {
            setVariantStock(0);
            stockLevelAction.current?.get(variant.id);
        }
    }, [variant]);

    const addHandler = () => {
        if (variant === undefined) {
            event?.("add-to-basked-no-variant");
            return;
        }

        setAdding(true);

        addItemToOrder.current?.add(1, variant.id).then((result) => {
            switch (result.__typename) {
                case "Order": {
                    sizeSelector.current?.reset();
                    openCart?.();
                    event?.("add-to-basked", { variantId: variant.id });
                    break;
                }
                case "InsufficientStockError":
                    event?.("insufficient-stock", { variantId: variant.id });
                    toast.open(t("insufficient_stock_error"));
                    break;
                case "NegativeQuantityError":
                case "OrderLimitError":
                case "OrderModificationError": {
                    event?.("add-to-basked-error", { variantId: variant.id });
                    toast.open(t("error_add_to_basket"));
                    break;
                }
            }
            setAdding(false);

            addItemToOrder.current?.resetStore();
        });
    };

    const consentHandler = () => {
        approve?.();
    };

    const variantSelected = variant !== undefined;
    const showPriceTag = variantSelected && variant.priceWithTax !== undefined;
    const inStock = (variantStock !== undefined && variantStock > 0);

    return (
        <>
            <StockLevelAction ref={stockLevelAction} onCompleted={(stockLevel) => {
                setVariantStock(stockLevel);
            }}/>
            <PublicDataAction ref={globalSettingsAction} />
            <AddItemToOrderAction ref={addItemToOrder} />

            <ProductAction ref={productAction} onProducts={handleLoadProduct} />

            <div className="grid">
                <div className="row">
                    <div className="one-half column">
                        {(!isLoading && assets) && <PreviewSlider galleryId={product?.slug} assets={assets} />}
                        {isLoading && <p>Loading images...</p>}
                    </div>

                    <div className="one-half column">
                        <div>
                            <h1 className={["pagetitle", classes.pagetitle].join(" ")}>{product?.name}</h1>

                            <p dangerouslySetInnerHTML={
                                { __html: product?.description || "" }
                            }></p>

                            <p className={classes.articleSlug}>{product?.slug} / {t("article_number")} {product?.id}</p>

                            <SocialSharingComponent product={product} />

                            <p style={{ marginBottom: 5 }}>{t("pick_a_size")}</p>
                            <div className="row">
                                <div className="column">
                                    <SizeSelector ref={sizeSelector} variants={product?.variants} onSelected={handleSizeSelected} />
                                </div>

                                {showPriceTag && <div className="column" style={{marginLeft: 0}}>
                                    <PriceTag price={variant?.priceWithTax} />
                                </div>}

                                {(showPriceTag && !inStock) && <div className="column" style={{marginLeft: 0, paddingTop: '1em'}}>
                                    <p>{t("contact_for_order")}<a href={`tel:${phone}`} itemProp="telephone">{phone}</a>.</p>
                                </div>}
                            </div>

                            <p>{t("contact_for_queries")}</p>

                            {(variantSelected && consent === true && inStock) && <Button 
                                fullWidth={true}
                                loading={adding}
                                onClick={addHandler}>{t("add_to_basket")}</Button>}
                            {(variantSelected && consent !== true) && <Button 
                                fullWidth={true}
                                onClick={consentHandler}>{t("consent_to_cookies")}</Button>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
