import { SelectHTMLAttributes } from "react";
import { useSelect, UseSelectParams } from "./useSelect";
import { useSelectOptions, UseSelectOptionsParams } from "./useSelectOptions";
import classes from "./VSelect.module.scss";

// More native props should pass through the props
// used only name, for testing purpose
type SelectProps = Pick<SelectHTMLAttributes<HTMLSelectElement>, "name">;

export function VSelect<Option>({
    selectedOption,
    options,
    onChange,
    getLabel,
    ...props
}: UseSelectParams<Option> & UseSelectOptionsParams<Option> & SelectProps) {
    const selectProps = useSelect({ selectedOption, options, onChange });
    const selectOptions = useSelectOptions({ options, getLabel });

    return (
        <select {...props} {...selectProps} style={{marginBottom: 0}} className={classes.select}>
            {selectOptions}
        </select>
    );
}
