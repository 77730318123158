import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function RegisteredPage() {
    const { t } = useTranslation("customer");
    
    return (
        <>
            <div className="grid-container">
                <div className="row">
                    <div className="sixteen column">
                        <h1>{t("customer_created")}</h1>
                        <p>{t("customer_created_text")}</p>

                        <Link to="/made-to-order">
                            <button>{t("browse_my_shop")}</button>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
}
