import { Navigate } from "react-router-dom";
import { useActiveCustomer } from "../../services";

type Props = {
    children: JSX.Element[] | JSX.Element,
};

export default function ProtectedRoute(props: Props) {
    const { children } = props;

    const { customer, hasLoaded, error } = useActiveCustomer();

    if (hasLoaded) {
        if (!customer) {
            return <Navigate to="/" replace />;
        }

        if (error) {
            console.error(error);
        } 
    }

    return (
        <>
            {children}
        </>        
    );
}
