import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache } from "@apollo/client";
import { BrowserRouter } from "react-router-dom";
import { CacheConfig } from "./CacheConfig";
import { setContext } from "@apollo/client/link/context";
import { default as localization } from "./i18n";
import { I18nextProvider } from "react-i18next";
import { CookiesProvider } from "react-cookie";
import { DrawerProvider, ActiveOrderProvider, ActiveCustomerProvider, AnalyticsProvider, LoadingProvider, ToastProvider, CookieConsentProvider } from "./services";
import { createNetworkStatusNotifier } from "react-apollo-network-status";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

const { link, useApolloNetworkStatus } = createNetworkStatusNotifier();

const protocol = process.env.REACT_APP_API_PROTOCOL || "https";
const uri = process.env.REACT_APP_API_URI;

const port = undefined;
const graphql_uri = `${protocol}://${uri}${(port ? ":" + port : "")}/shop-api`;

const AUTH_TOKEN_KEY = "auth_token";

const getCookieValue = (name: string) => (
    document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")?.pop() || ""
);

const httpLink = new HttpLink({
    uri: () => {
        const languageCode = getCookieValue("language");

        if (languageCode) {
            return `${graphql_uri}?languageCode=${languageCode}`;
        }
        return `${graphql_uri}`;
    }
});

const afterwareLink = new ApolloLink((operation, forward) => {
    return forward(operation).map((response) => {
        const context = operation.getContext();
        const authHeader = context.response.headers.get("vendure-auth-token");
        if (authHeader) {
            // If the auth token has been returned by the Vendure
            // server, we store it in localStorage  
            localStorage.setItem(AUTH_TOKEN_KEY, authHeader);
        }
        return response;
    });
});

const client = new ApolloClient({
    link: ApolloLink.from([
        link,
        setContext((request, operation) => {
            const authToken = localStorage.getItem(AUTH_TOKEN_KEY);
            let headers: Record<string, any> = {};
            if (authToken) {
                headers.authorization = `Bearer ${authToken}`;
            }
            return { headers };
        }),
        afterwareLink,
        httpLink,
    ]),
    cache: new InMemoryCache(CacheConfig),
});

root.render(
    <React.StrictMode>
        <CookiesProvider defaultSetOptions={{ path: "/" }}>
            <AnalyticsProvider>
                <I18nextProvider i18n={localization}>
                    <ApolloProvider client={client}>
                        <BrowserRouter>
                            <LoadingProvider useStatus={useApolloNetworkStatus}>
                                <CookieConsentProvider>
                                    <ActiveCustomerProvider>
                                        <ActiveOrderProvider>
                                            <DrawerProvider>
                                                <ToastProvider>
                                                    <App />
                                                </ToastProvider>
                                            </DrawerProvider>
                                        </ActiveOrderProvider>
                                    </ActiveCustomerProvider>
                                </CookieConsentProvider>
                            </LoadingProvider>
                        </BrowserRouter>
                    </ApolloProvider>
                </I18nextProvider>
            </AnalyticsProvider>
        </CookiesProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
