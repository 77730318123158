import { useState } from "react";
import { VSelect } from "../VSelect/VSelect";
import { useAnalytics } from "../../services";

type Language = {
    value: string;
    label: string;
};

const options = [
    { value: "sv", label: "🇸🇪" },
    { value: "en", label: "🇬🇧" },
    // { value: "ru", label: "🇷🇺" },
] as const;

const getLabel = ({ label }: Language) => label;

export default function LanguageSelect(props: {
    defaultCode: string,
    onLanguageChanged: (language: Language) => void}
) {
    const { event } = useAnalytics();

    const { defaultCode, onLanguageChanged } = props;

    const [selectedOption, setSelectedOption] = useState<Language | undefined>(options.find((lan) => lan.value === defaultCode));
    
    return (
        <VSelect
            name="language"
            selectedOption={selectedOption === undefined ? options[0] : selectedOption}
            options={options}
            getLabel={getLabel}
            onChange={(language) => {
                event?.("language-changed", { language: language });
                setSelectedOption(language);
                onLanguageChanged(language);
            }}
        />
    );
}
