import React from 'react';
import AppRoutes from './pages';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import 'keen-slider/keen-slider.min.css'
import './styles/verasatelje.scss';

function App() {
    return (
        <>
            <AppRoutes />
            <ScrollToTop />
        </>
    );
}

export default App;
