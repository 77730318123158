import jacket from "./jacket.jpeg";
import weddingDress from "./wedding_dress.jpeg";
import sewingMachine from "./sewing_machine.jpeg";
import classes from "./About.module.scss";
import { useTranslation } from "react-i18next";

export default function About() {

    const { t } = useTranslation("about");

    const aboutHeading = t("my_studio_heading");
    const aboutTexts = t("my_studio_text");

    const productsHeading = t("products_heading");
    const productsTexts = t("products_text");

    return (
        <>
            <div className="row">
                <div className="sixteen columns">
                    <h1 className="pagetitle">Om Veras Ateljé</h1>
                </div>
            </div>

            <div className="row">
                <div className="one-third column">
                    <img className={classes.image} src={weddingDress} alt="Justering av bröllopsklänning."/>
                </div>
                <div className="two-thirds column">
                    <h3 className={classes.heading}>{aboutHeading}</h3>
                    {aboutTexts.split("\n").map((text: string, index: number) => (
                        <p key={`about-${index}`}>{text}</p>
                    ))}
                </div>
            </div>

            <div className="row">
                <div className="one-third column">
                    <img className={classes.image} src={jacket} alt="Kavaj."/>
                </div>
                <div className="one-third column">
                    <img className={classes.image} src={sewingMachine} alt="Symaskin."/>
                </div>
            </div>

            <div className="row">
                <div className="one-half column">
                    <h3 className={classes.heading}>{productsHeading}</h3>
                    {productsTexts.split("\n").map((text: string, index: number) => (
                        <p key={`products-${index}`}>{text}</p>
                    ))}
                </div>
                <div className="one-half column">
                    {/* <img className={classes.image} src={provdockor} alt="industrisymaskin och provdockor"/> */}
                </div>
            </div>

        </>
    );
}
